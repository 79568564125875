<template>
  <div
    class="main-single-page welcome"
    tabindex="0"
    role="button"
    @click="setMenuStatus(true)"
  >
    <span class="open-main-menu">
      <svg-icon icon="menu"></svg-icon>
    </span>
    <large-logo
      :welcome="true"
      :welcomeText="displayLabelName('global.messages.please-use-the-menu')"
    ></large-logo>
  </div>
</template>

<script>
import LargeLogo from "@/components/global/LargeLogo";
import { mapActions } from "vuex";
import { getSubdomain } from "@/services/http-service";

export default {
  name: "Welcome",
  computed: {
    subdomain() {
      return getSubdomain();
    },
  },
  created() {
    this.$store.commit("global/setIsFullWidthScreen", true, {
      root: true,
    });
  },
  methods: {
    ...mapActions("global", ["setMenuStatus"]),
  },
  components: {
    LargeLogo,
  },
  beforeUnmount() {
    this.$store.commit("global/setIsFullWidthScreen", false, {
      root: true,
    });
  },
};
</script>
